import React, { useState, useLayoutEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { analyticClickEvent } from "../../components/googleAnalytics"
import {
  ArticleDate,
} from "../../styledComponents/article"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
  StyledVideoTestimonialLede
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"
import { StyledButton } from "../../styledComponents/button"
import ShareTools from "../../components/socialLinks"
import { StyledShareSocialTools } from "../../components/Products/StyledPrimaryProductCard"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const SuperchargingNiagraFallsExamples = ({ data, location }) => {
  

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    analyticClickEvent("engagement", "CTA Button","Watch Full Richard Gordon Video",'')
    setIsWatchFullVersionOpen(value)
  }

  const pageUrl = `https://${process.env.GATSBY_DOMAIN_NAME}/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch`

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <Layout displayNavBorder location={location} hideNewsletterSignUp>
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">Quantum-Touch Supercharging Niagra Falls Example</h1>
          <ArticleDate offScreen dateTime=''>Last updated on September 22nd 2023</ArticleDate>
        </StyledVideoTestimonialHeader>
        <StyledVideoTestimonialVideo>
       
        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="Karina Grant, Quantum-Touch Instructor & Ambassador talking about resonance in Nature with Quantum-Touch" width="640" height="360" src={'https://karinagrant.s3.eu-west-1.amazonaws.com/videos/niagra-falls-night.mp4'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" controlsList="nodownload" allowfullscreen=""></iframe></div></figure>

        <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="Karina Grant, Quantum-Touch Instructor & Ambassador talking about resonance in Nature with Quantum-Touch" width="640" height="360" src={'https://karinagrant.s3.eu-west-1.amazonaws.com/videos/niagra-falls-day.mp4'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" controlsList="nodownload" allowfullscreen=""></iframe></div></figure>
        </StyledVideoTestimonialVideo>
      </StyledVideoTestimonialArticle>

      
    </Layout>
    </>
  )
}

export default SuperchargingNiagraFallsExamples

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
